/* HeroSection.css */
.hero-container-Contacts {
    height: 50%;
    width: 50%;
    padding-left: 25%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background-repeat: repeat;
    color: rgba(255, 255, 255, 1);
    text-align: center;          
}

.hero-container-Contacts h2{
    padding: 2%;
    margin: 0;
    font-size: 30px;
}

.hero-container-Contacts h1{
    margin-top: 5%;
    font-size: 70px;
}

.hero-container-Images {
    display: flex;
    flex-direction: row;
    align-items: center;
    object-fit: contain;
    background-repeat: repeat;
    color: rgba(255, 255, 255, 1);
    text-align: center;   
}
.card-image-contacts{
    width: 150px;
    height: auto;
    padding-left: 0px;
    max-width:  75%;
    padding: 5%;
    margin: 0;
}

