.project {
    color: white;
}

.ProjectDescription {
    opacity: 100%;
    margin: 0 auto; 
    max-width: 900px;
}

.project h1 {
    font-size: 80px;
    font-size: 70px;
    margin: 0 auto;
    margin-bottom: -20px;
    text-align: center;
}

.project h2 {
    font-size: 25px;
    margin-bottom: 50px;
    text-align: center;
}

.project p {
    font-size: 18px;
}


.projectCardImage {
    display: inline-block;
    margin: 0;
    padding-right: 4%;
    margin-bottom: 20px;
}
.projectCardVideo{
    display: inline-block;
}
.ProjectVideo{
    margin-bottom: 20px;
}

.projectCardImage img {
    width: 400px;
}
.projectCardImage figcaption {
    max-width: 400px;
}

.card-video-controls {
    width: 500px;
    margin: 0 auto; 
}

.projectCardImage .card-video-controls {
    align-items: center;
    margin-left: 50px;
}
.preImageDescription {
    padding-bottom: 1%;
}
figcaption{
    text-align: center;
}
