/* HeroSection.css */
.hero-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
    background-repeat: repeat;
    color: rgba(255, 255, 255, 1);
    text-align: center;
}

.hero-container h2{
    font-size: 35px;
}

.hero-container h1{
    font-size: 60px;
}