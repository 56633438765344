/* Navbar.css */
.navbar-container {
    display: flex;
    padding: 10px;
    max-width: 1920px; 
    margin: 0 auto; 
}

.navbar-container a {
    text-decoration: none;
    color: #333;
    padding: 8px 16px;
    margin-right: 10px; /* Adjust the margin as needed */
    border-radius: 4px;
    background-color: #f0f0f0;
    transition: background-color 0.3s;
}

.navbar-container a:last-child {
    margin-right: 0;
}

.navbar-container a:hover {
    background-color: #ddd;
}
