.card{
    display: inline-block;
    width: 400px;
}

.card-image{
    width: 200px;
    height: auto;
    padding-left: 0px;
}
.description{
    width: 800%;
    max-width: 80%;
    opacity: 100%;
    font-size: 20px;
    display: inline-block;
}
.title{
    height: 0px;
    opacity: 100%;
    font-size: 26px;
    display: inline-block;
}
.card-button {
    background-color: transparent;
    border: none;
}
h1{
    color: greenyellow;
}
